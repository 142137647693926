import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ProductInventoryItemUtilsService } from '@app/modules/service-data/services/product-inventory-item-utils.service';
import { ProductInventoryItemService } from '@app/modules/service-data/services/product-inventory-item.service';
import { SetProductInventoryItemsAction } from '@app/modules/service-data/store/product-intentory-item.actions';
import { ProductInventory, Run } from '@core/core.types';
import { RunState } from '@core/store/runs/runs.state';
import { sortRunByDateGenerated } from '@core/utils/core.util';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
  NbInputModule,
  NbOptionModule,
  NbSelectModule,
  NbToastrService,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { inputAtLeastOneValidator } from '@shared/validators/input-at-least-one-validator';
import { Observable, debounceTime, map, switchMap } from 'rxjs';

@Component({
  selector: 'app-product-inventory-item-search',
  standalone: true,
  imports: [
    AsyncPipe,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    NbSelectModule,
    NbOptionModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './product-inventory-item-search.component.html',
})
export class ProductInventoryItemSearchComponent {
  protected searchForm = new FormGroup(
    {
      id: new FormControl<string>(''),
      kitToken: new FormControl<string>(''),
      run: new FormControl<Run>(null),
    },
    [inputAtLeastOneValidator()]
  );

  protected loading = false;

  protected runs$: Observable<Run[]>;

  private destroyRef = inject(DestroyRef);

  constructor(
    private dialogRef: NbDialogRef<ProductInventoryItemSearchComponent>,
    private store: Store,
    private toastr: NbToastrService,
    private piiService: ProductInventoryItemService,
    private piiUtilsService: ProductInventoryItemUtilsService
  ) {
    this.runs$ = this.store.select(RunState.getAllRuns).pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(100),
      map((runs: Run[]) =>
        sortRunByDateGenerated(runs.filter((r) => r && r.id))
      )
    );
  }

  protected search() {
    if (this.searchForm.invalid) {
      return;
    }

    this.loading = true;

    this.piiService
      .getProductInventoryItemList(
        {
          ids: this.searchForm.get('id').value
            ? [this.searchForm.get('id').value]
            : null,
          kitToken: this.searchForm.get('kitToken').value,
          kitIds: this.searchForm
            .get('run')
            .value?.kits?.map((kit) => kit.kitId),
        },
        {}
      )
      .pipe(
        switchMap((result) =>
          this.store
            .dispatch(new SetProductInventoryItemsAction(result.items))
            .pipe(map(() => result))
        )
      )
      .subscribe({
        next: (result) => {
          const piiItems = result.items;
          if (piiItems.length === 1) {
            this.piiUtilsService.openDetails(piiItems[0].id);
            this.dismiss();
          } else if (piiItems.length > 1 && piiItems.length < 100) {
            this.piiUtilsService.openResults(
              piiItems.map((pii: ProductInventory) => pii.id)
            );
            this.dismiss();
          } else if (piiItems.length >= 100) {
            this.toastr.danger(
              `Too many results returned, narrow your search!`,
              'Too many results!',
              { duration: 5000 }
            );
          } else {
            this.toastr.danger(`Query returned no results!`, 'Not found!', {
              duration: 5000,
            });
          }
          this.loading = false;
        },
        error: () => {
          this.toastr.danger(`Query returned no results!`, 'Not found!', {
            duration: 5000,
          });
          this.loading = false;
        },
      });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
